import { graphql } from "gatsby";
import Image from "gatsby-image";
import React from "react";
import { Layout } from "../components/Layout";



export default ({ data }) => (
  <Layout header="songs">
    <div className="grid grid-cols-2 sm:grid-cols-1 gap-x-1 gap-y-5">
    
    </div>
  </Layout>
);
